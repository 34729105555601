.tetris {
  margin: 1em;
}

.game-block {
  margin: 0;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #ddd;
}

.piece-i {
  background-color: #ec858b;
}

.piece-j {
  background-color: #f1b598;
}

.piece-l {
  background-color: #f8efae;
}

.piece-o {
  background-color: #b5a677;
}

.piece-s {
  background-color: #816e56;
}

.piece-t {
  background-color: #b77c72;
}

.piece-z {
  background-color: #e3be58;
}

.piece-preview {
  background-color: #eee;
}

.parent {
  display: flex;
  width: 55%;
}

.parent > div {
  min-width: 300px;
  flex-grow: 1;
}

.parent > div > p {
  width: 100%;
}

.tetris-header {
  font-weight: 600;
  font-size: 1.8rem;
}

#held-piece {
  margin-top: 0;
}
